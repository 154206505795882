<template style="margin-bottom: 160px; margin-top: 10px;">
  <div>
    <vx-card :title="card.title + (card.subTitle ? ' | ' : '') + card.subTitle"
             style="margin-bottom: 160px; margin-top: 10px;">
      <vs-tabs :color="colorx" v-model="tabs">
        <vs-tab label="Export" @click="card.subTitle='Core Tax'">
          <div class="con-tab-ejemplo">
            <export_xml />
          </div>
        </vs-tab>
        <vs-tab label="Import" @click="card.subTitle='Core Tax'">
          <div class="con-tab-ejemplo">
            <import_xml />
          </div>
        </vs-tab>
      </vs-tabs>
    </vx-card>
  </div>
</template>

<script>
import export_xml from './export_xml/export-xml.vue'
import import_xml from './import_xml/import-xml.vue'

export default {
  components: {
    export_xml,
    import_xml
  },
  data() {
    return {
      card: {
        title: "Tax Invoice",
        subTitle: "Export XML"
      },
      colorx: "success",
      tabs: 0,
      taxNumberLength: 0,
    };
  },
}
</script>
