<template>
  <div class="vx-row" style="margin-top: 10px" ref="outside">
    <div class="vx-col md:w-1/1 w-full" style="margin-bottom: 160px;padding-top: 30px;">
      <div class="vx-row mb-6 ml-4 mr-4" style="z-index: 99999 !important">
        <div class="vx-col sm:w-1/5 w-full">
          <span>Company</span>
        </div>
        <div class="vx-col sm:w-2/5 w-full">
          <formInputs :components="this.formInput.inputs" />
        </div>
      </div>
      <div class="vx-row mb-6 ml-4 mr-4">
        <div class="vx-col sm:w-1/5 w-full">
          <span>Supplier</span>
        </div>
        <div class="vx-col sm:w-2/5 w-full">
          <formInputs :components="this.formSupplier.inputs" @handlerSearch="handlerSearchMS"
                      @update-forminput="handleSelect" />
        </div>
      </div>
      <div class="vx-row mb-6 ml-4 mr-4">
        <div class="vx-col sm:w-1/5 w-full">
          <span>Tax Invoice Period</span>
        </div>
        <div class="vx-col sm:w-1/5 w-full">
          <formInputs :components="this.formMonth.inputs" />
        </div>
        <div class="vx-col sm:w-1/5 w-full">
          <formInputs :components="this.formYear.inputs" />
        </div>
      </div>
      <div class="vx-row mb-6 ml-4 mr-4">
        <div class="vx-col sm:w-1/5 w-full">
          <span>Search</span>
        </div>
        <div class="vx-col sm:w-2/5 w-full">
          <vs-input v-model="table.search" class="w-full" />
        </div>
      </div>
      <div class="vx-row mb-6 ml-4 mr-4">
        <div class="vx-col sm:w-4/5 w-full text-center">
          <vs-button class="mr-4" @click="handleView()" color="primary" icon-pack="feather">View
          </vs-button>
          <vs-button v-if="tabs == 0" :disabled="!this.itemRefs.some((dt) => dt.inputs[1].components.value)"
                     @click="handleSubmit()" color="danger" icon-pack="feather" type="border">Export
          </vs-button>
        </div>
      </div>
      <vs-row class="mb-6 ml-4 mr-4" vs-w="12">
        <vs-col class="mb-3" vs-type="flex" vs-justify="left" vs-align="center" vs-w="3">
          <span>Total Invoice : {{ this.table.total }}</span>
        </vs-col>
        <vs-col class="mb-3" vs-type="flex" vs-justify="left" vs-align="center" vs-w="8">
          <span>Total SN Available : {{ this.total_sn_available }}</span>
        </vs-col>
      </vs-row>
      <vs-divider class="mb-2"></vs-divider>
      <vs-tabs :color="colorx" v-model="tabs">
        <vs-tab @click="
          colorx = 'success';
        tabClick(gen);
        edit = false
          " label="List of Debit Note">
          <div class="con-tab-ejemplo">
            <div class="flex">
              <div :class="[edit ? 'vx-col w-full mb-base md:w-2/3' : '', 'core vx-col w-full mb-base md:w-1/1']">
                <datatable :style="[edit ? { 'padding-right': '1rem' } : {}]" :itemRefs="this.itemRefs"
                           :tablep="this.table" :modelItemRefInput="this.tmpitemRef" :hideSearch="true"
                           @change-length="handleChangelength" @search-itemref="handleSearch"
                           @handleChangePage="handleChangePage" @update-itemref="updateitemref" @input-itemref="oninput"
                           @click="onclick" @handleSort="handleSort" />
              </div>
              <transition name="detail-fade">
                <div class="vertical-divider vx-col w-full mb-base" v-if="edit"
                     :class="[edit ? 'core md:w-1/3' : 'close md:w-1/1']">
                  <vs-col style="padding-bottom: 8px" vs-offset="8" vs-type="flex" vs-justify="right" vs-align="right"
                          vs-w="4">
                    <vs-button class="ml-auto min-h-0" size="small" v-on:click="handleClose" color="grey"
                               icon-pack="feather" icon="icon-x-square">Close</vs-button>
                  </vs-col>
                  <vs-col class="flex flex-row align-middle min-h-0">
                    <div class="flex items-center w-9">
                      <vs-button size="small" v-on:click="handleClose" color="grey" icon-pack="feather"
                                 icon="icon-x-square"></vs-button>
                    </div>
                    <div class="flex-1 mx-2">
                      Edit Line
                      <div v-for="(line, idx) in dnLines" :key="idx" class="border-2 rounded">
                        <div class="vx-row mb-6">
                          <div class="vx-col w-full">
                            <vs-input class="w-full" label="Description" name="description"
                                      v-model="line.Description" />
                          </div>
                        </div>
                        <div class="vx-row mb-6">
                          <div class="vx-col w-full">
                            <vs-input class="w-full" label="Line Value" name="dpp" :value="formatNumber(line.Dpp)"
                                      @keypress="isNumber($event)" @change="onChangeDpp(idx, $event)" />
                          </div>
                        </div>
                        <div class="flex justify-end space-x-1">
                          <vs-button color="success" icon-pack="feather" icon="icon-plus"
                                     v-show="idx == dnLines.length - 1" @click="addLine(idx)" />
                          <vs-button color="danger" icon-pack="feather" icon="icon-minus"
                                     v-show="idx == dnLines.length - 1" :disabled="dnLines.length === 1"
                                     @click="removeLine(idx)" />
                        </div>
                      </div>
                      <div>
                        Total: {{ formatNumber(dnLines.reduce((prev, curr) =>
                        prev + parseFloat(curr.Dpp.toString().replace(/,/g, "")), 0)) }}
                      </div>
                      <vs-button @click="saveLine">Save</vs-button>
                    </div>
                  </vs-col>
                </div>
                <div v-else></div>
              </transition>
            </div>
          </div>
        </vs-tab>
        <vs-tab @click="
          colorx = 'warning';" label="History">
          <div class="con-tab-ejemplo">
            <export_table :hideSearch="true" type="tax-invoice-debit-note-xml" />
          </div>
        </vs-tab>
      </vs-tabs>
    </div>
  </div>
</template>

<script>
import datatable from "../datatable.vue";
import formInputs from "../form_inputs.vue";
import moment from "moment";
import export_table from "../export_table.vue";
export default {
  components: {
    export_table,
    datatable,
    formInputs,
  },
  data() {
    var baseUrl = window.location.origin
    return {
      edit: false,
      dnLines: [],
      historyLine: [],
      deleteIds: [],
      dn: {},
      descs: {},
      baseUrl: baseUrl,
      selected: {},
      responseData: {},
      users: [],
      type: 0,
      gen: 0,
      data_gen: 1,
      tab: 0,
      colorx: "success",
      id_dn: [],
      isAll: false,
      data: {
        npwp: "",
      },
      table: {
        start: 0,
        end: 0,
        page: 0,
        stripe: false,
        length: 10,
        search: "",
        order: "id",
        sst: true,
        sort: "desc",
        total: 0,
        totalPage: 0,
        totalSearch: 0,
        limits: [5, 10, 25, 50, 100, "All"],

      },
      tabs: 0,
      formTaxEntity: {
        id: 1,
        inputs: {
          disabled: false,
          validate: "required",
          name: "taxt_entity",
          placeholder: "Type to search",
          type: "multiselect",
          value: {},
          option: [],
          multiple: false,
          allowempty: false,
          track_by: "ID",
          onSelectEvent: (selected) => {
            console.log(selected);
          },
        },
      },
      formSupplier: {
        id: 2,
        inputs: {
          disabled: false,
          validate: "required",
          name: "supplier",
          placeholder: "Type to search",
          type: "multiselect",
          value: [{ ID: 0, text: "All" }],
          option: [],
          multiple: true,
          allowempty: false,
          internal_search: false,
          clear_on_select: false,
          track_by: "ID",
          onSelectEvent: (selected) => {
            console.log(selected);
          },
        },
      },
      tempSupplier: [],
      formTaxInvoiceType: {
        id: 4,
        inputs: {
          disabled: false,
          validate: "required",
          name: "territory",
          placeholder: "Type to search",
          type: "multiselect",
          value: {},
          option: [],
          multiple: false,
          allowempty: false,
          track_by: "ID",
          onSelectEvent: (selected) => {
            console.log(selected);
          },
        },
      },
      formInvoiceType: {
        id: 5,
        inputs: {
          disabled: false,
          validate: "required",
          name: "invoice_type",
          placeholder: "Type to search",
          type: "multiselect",
          value: {
            id: 1,
            text: "Debit Note"
          },
          option: [
            {
              id: 0,
              text: "AR Invoice"
            },
            {
              id: 1,
              text: "Debit Note"
            }
          ],
          multiple: false,
          allowempty: false,
          track_by: "id",
        },
      },
      formInput: {
        id: 6,
        inputs: {
          disabled: false,
          validate: "required",
          name: "tax_entity",
          placeholder: "Tax Entity",
          type: "input",
          value: "",
        },
      },
      formYear: {
        id: 7,
        inputs: this.forminputyear(),
      },
      formMonth: {
        id: 8,
        inputs: this.forminputmonth(),
      },
      itemRefs: [],
      tmpitemRef: {
        id: 2,
        action: false,
        inputs: this.getInputsHead(),
      },
      dtGen: [],
      tmpdtGen: {
        id: 2,
        action: false,
        inputs: this.getInputsHeadDataGen(),
      },
      dtGenHistory: [],
      tmpdtGenHistory: {
        id: 2,
        action: false,
        inputs: this.getInputsHeadDataGenHistory(),
      },
      DataOperatingUnit: [],
      DataSupplier: [],
      data_head: {},
      total_sn_available: 0,
    };
  },
  mounted() {
    this.getData();
    console.log("Router: ", this.$router);
  },
  computed: {
    formattedDpp: {
      get() {
        return this.line.Dpp;
      },
      set(value) {
        this.line.Dpp = value;
        this.onChangeDpp(this.idx);
      }
    },
    setPage: {
      get() {
        return 1;
      },
      set(val) {
        this.handleChangePage(val);
      },
    },
  },
  methods: {
    formatNumber(val) {
      if (isNaN(val)) {
        return 0
      }
      return Intl.NumberFormat("en", { minimumFractionDigits: 2, }).format(val)
    },
    onChangeDpp(idx, event) {
      const value = event.target.value
      const newDpp = value.replace(/,/g, "")
      this.dnLines[idx].Dpp = parseFloat(newDpp)
    },
    saveLine() {
      const isValid = this.dnLines.every(line => line.Dpp !== 0);

      if (!isValid) {
        this.$vs.notify({
          color: "danger",
          title: "Error",
          text: "DPP must not be 0 (zero)",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-x-circle",
        });
        return
      }

      if (parseFloat(this.dn.DPP) != this.dnLines.reduce((prev, curr) => prev + parseFloat(curr.Dpp), 0)) {
        this.$vs.notify({
          color: "danger",
          title: "Error",
          text: "DN Value and Total DPP must be the same",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-x-circle",
        });
        return
      }

      this.$vs.loading();
      this.$http
        .put(`api/v1/master/tax-invoice/view-dn-line/${this.dn.ID}`, {
          lines: this.dnLines.map((d) => ({
            id: d.ID,
            description: d.Description,
            dpp: parseFloat(d.Dpp),
          })),
          delete_ids: this.deleteIds,
        })
        .then((resp) => {
          this.$vs.loading.close();
          if (resp.code == 500) {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          } else if (resp.code == 200) {
            this.handleClose()
            this.handleView()
          }
        })
        .catch((err) => {
          this.$vs.loading.close();
          this.$vs.notify({
            color: "danger",
            title: "Error",
            text: err,
            position: "top-right",
            iconPack: "feather",
            icon: "icon-x-circle",
          });
          console.log("error boss:", err);
        });
    },
    addLine(idx) {
      this.dnLines.splice(idx + 1, 0, {
        Description: "",
        Dpp: 0,
        ID: 0,
      })
    },
    removeLine(idx) {
      this.deleteIds.push(...this.dnLines.splice(idx, 1).map(d => d.ID))
    },
    async getDetailHistory(historyId) {
      try {
        this.$vs.loading();
        const response = await this.$http.get(`api/v1/master/tax-invoice/view-generate-history/${historyId}`)

        this.historyLine = response.data.records.Data.debit_notes.map((item) => ({
          ID: item.invoice_id,
          Code: item.debit_note_code,
          Status: item.status,
          Message: item.status.toLowerCase() == "success" ? item.tax_number : item.message
        }))
      } catch (error) {
        console.log(error)
      } finally {
        this.$vs.loading.close();
      }
    },
    getLine(dnId) {
      this.$vs.loading();
      this.$http
        .get(`api/v1/master/tax-invoice/view-dn-line/${dnId}`)
        .then((resp) => {
          // console.log(this.option.event, 'option')
          this.$vs.loading.close();
          if (resp.code == 500) {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          } else if (resp.code == 200) {
            this.dnLines = resp.data.faktur_pajak_dn_lines.map((dnLine) => ({
              ID: dnLine.ID,
              Description: dnLine.Description,
              Dpp: dnLine.Dpp
            }))
            this.dn = resp.data.debit_note
            this.edit = true
          }
        })
        .catch((err) => {
          this.$vs.loading.close();
          this.$vs.notify({
            color: "danger",
            title: "Error",
            text: err,
            position: "top-right",
            iconPack: "feather",
            icon: "icon-x-circle",
          });
          console.log("error boss:", err);
        });
    },
    handleClose() {
      this.deleteIds = []
      this.dn = {}
      this.dnLines = []
      this.historyLine = []
      this.edit = false
    },
    onclick(e) {
      console.log(e)
      const dt = e.data
      console.log("dt", dt)
      this.getLine(dt.ID)
    },
    onclickHistory(e) {
      this.edit = true
      const dt = e.data
      this.getDetailHistory(dt.ID)
    },
    leadingZero(value, fixLength) {
      return "0".repeat(fixLength - value.length) + value
    },
    forminputyear() {

      return {
        disabled: false,
        validate: "required",
        name: "year",
        placeholder: "Select Year",
        type: "date",
        min_view: "year",
        format: 'yyyy',
        value: new Date(),
        multiple: false,
        allowempty: false,
        track_by: "ID",
        onSelectEvent: (selected) => {
          console.log(selected);
        },
      };
    },
    handleSearch(search) {
      this.table.search = search
      this.handleView()
    },
    handlerSearchMS(comp, search) {
      if (comp.name == "supplier") {
        this.formSupplier.inputs.option = this.tempSupplier.filter(v => {
          var tempText = v.text.toLowerCase()
          var tempSearch = search.toLowerCase()
          return tempText.search(tempSearch) >= 0
        })
      }
    },
    handleSelect(selected, comp) {

    },
    forminputmonth() {

      return {
        disabled: false,
        validate: "required",
        name: "month",
        placeholder: "Select Month",
        type: "date",
        min_view: "month",
        format: 'MM',
        // type: "multiselect",
        value: new Date(),
        // option: dtselect,
        multiple: false,
        allowempty: false,
        track_by: "ID",
        onSelectEvent: (selected) => {
          console.log(selected);
        },
      };
    },
    handleChangelength(item) {
      console.log(item)
      if (item == "All") {
        this.table.end = this.table.total
      } else {
        this.table.end = item
      }
      this.table.length = this.table.end
      this.handleView()
    },
    resetTable() {
      this.table.total = 0
      this.table.totalPage = 0
      this.table.totalSearch = 0
      this.table.length = 10
      this.table.start = 0
      this.table.end = 0
      this.table.stripe = false
    },
    tabClick(i) {
      this.resetTable()
      if (i == 1) {
        this.table.stripe = false
        this.getDatGen()
      } else if (i == 2) {
        this.getDataGenHistories()
      } else {
        this.getDataSN()
      }
    },
    oninputSearch() {
      this.getDatGen()
    },
    oninput(data, type) {
      console.log(data);
      if (type == "itemref") {
        var numb = data.value
        console.log("numb", numb)
        if (data.name == "start_number") {
        } else if (data.name == "end_number") {
        } else if (data.name == "checkbox") {
          var id = data.id_dn
          if (!id) {
            id = 0
          }
          if (data.head_val != undefined) {
            this.id_inv = []
            this.data_head = data;
            if (data.head_val == true) {
              this.isAll = true
              if (this.tabs == 0) {
                this.itemRefs.forEach((dt, i) => {
                  if (!this.itemRefs[i].inputs[1].components.disabled) {
                    this.itemRefs[i].inputs[1].components.value = true;
                    this.id_dn.push(this.itemRefs[i].id_dn)
                  }
                });
              } else if (this.tabs == 1) {
                this.dtGen.forEach((dt, i) => {
                  if (!this.dtGen[i].inputs[1].components.disabled) {
                    this.dtGen[i].inputs[1].components.value = true;
                    this.id_dn.push(this.dtGen[i].id_dn)
                  }
                });
              }
            } else if (data.head_val == false) {
              this.isAll = false
              this.id_dn = []
              if (this.tabs == 0) {
                this.itemRefs.forEach((dt, i) => {
                  if (!this.itemRefs[i].inputs[1].components.disabled) {
                    this.itemRefs[i].inputs[1].components.value = false;
                  }
                });
              } else if (this.tabs == 1) {
                this.dtGen.forEach((dt, i) => {
                  if (!this.dtGen[i].inputs[1].components.disabled) {
                    this.dtGen[i].inputs[1].components.value = false;
                  }
                });
              }
            }
          } else {
            if (data.value) {
              this.id_dn.push(id)
              const row = this.itemRefs.find(d => d.id_dn == id)
              this.descs[row.inputs[3].components.value] = row.inputs[11].components.value
            } else {
              this.id_dn = this.id_dn.filter((v) => {
                return v != id;
              });
            }
            if (data.value == false) {
              if (this.data_head) {
                this.data_head.head_val = false;
              }
            }
          }
        } else if (data.name == "description") {
          this.descs[data.code] = data.value
        }
      }
    },
    updateitemref(data, status) {
      if (status == "add") {
        let i_new = this.itemRefs.length;
        this.itemRefs.push(data);
        for (let i = 0; i < this.itemRefs[i_new].inputs.length; i++) {
          this.itemRefs[i_new].inputs[i].components.id = i_new;
          if (i != 0 && i != 1 && i != 2) {
            this.itemRefs[i_new].inputs[i].components.value = "";
          } else {
            this.itemRefs[i_new].inputs[i].components.value =
              this.itemRefs[i_new - 1].inputs[i].components.value;
          }
        }
      } else if (status == "remove") {
        this.itemRefs = this.itemRefs.filter((v, i) => {
          return i != data;
        });
      }
    },
    getInputs(data = null, i = 0, no = 1) {
      var inputs = [];
      var periode = "";
      var invoice_no = "";
      var customer_code = "";
      var customer_name = "";
      var status_config = "";
      var tax_type = "";
      var type_inv = "";
      var custRoute = ""
      var invRoute = ""
      var ppn = 0
      var dn_value = 0
      var checkbox = false
      var id_dn = 0
      var description = ""
      if (data) {
        periode = data.PostingDate;
        if (periode) {
          periode = moment(periode).format("YYYY-MM-DD");
        }
        invoice_no = data.Code;
        customer_code = data.SupplierCode;
        customer_name = data.SupplierName;
        status_config = data.StatusConfig;
        type_inv = this.formInvoiceType.inputs.value.text//debit note
        invRoute = this.$router.resolve({
          name: 'dn-manual-detail',
          params: { status: data.Status, code: data.Code },
        })
        invRoute = invRoute.href
        custRoute = data.Url
        dn_value = data.DPP
        ppn = data.TaxValue
        id_dn = data.ID
        // tax_type = `${data.tax_type_code} ${data.tax_type_name}`;
        tax_type = `${data.TaxType}`;
        description = data.DebitNoteDescription != "" ? data.DebitNoteDescription : data.Description
      }

      inputs.push({
        components: {
          id: i,
          id_input: 0,
          disabled: true,
          validate: "required",
          name: "no",
          width: 40,
          text_align: "text-center",
          placeholder: "0",
          title: "No",
          type: "text",
          value: "" + no,
        },
      });
      inputs.push({
        components: {
          id: i,
          id_input: 1,
          disabled: status_config.toLowerCase() != "ready".toLowerCase(),
          validate: "required",
          name: "checkbox",
          placeholder: "Checkbox",
          title: "checkbox",
          head: "title", //checkbox/title
          type: "checkbox",
          id_dn: id_dn,
          value: checkbox,
        },
      });
      inputs.push({
        components: {
          id: 0,
          id_input: 0,
          disabled: false,
          validate: "",
          name: "action",
          placeholder: "Action",
          title: "Action",
          type: "button",
          icon: "icon-eye",
          color: "warning",
          class: "p-3 pr-2",
          value: "",
          data: data,
        },
      });
      inputs.push({
        components: {
          id: i,
          id_input: 2,
          disabled: true,
          validate: "required",
          name: "dn_date",
          placeholder: "Debit Note Date",
          title: "Debit Note Date",
          type: "text",
          value: periode,
        },
      });
      inputs.push({
        components: {
          id: i,
          id_input: 2,
          disabled: false,
          validate: "required",
          name: "dn_code",
          placeholder: "Debit Note code",
          title: "Debit Note code",
          target: "_blank",
          href: invRoute,
          type: "text-link",
          value: invoice_no,
        },
      });
      inputs.push({
        components: {
          id: i,
          id_input: 4,
          disabled: true,
          validate: "required",
          name: "tax_type",
          placeholder: "Tax Type",
          title: "Tax Type",
          type: "text",
          value: tax_type,
        },
      });
      inputs.push({
        components: {
          id: i,
          id_input: 4,
          disabled: true,
          validate: "required",
          name: "invoice_type",
          placeholder: "Invoice Type",
          title: "Invoice Type",
          type: "text",
          value: type_inv,
        },
      });
      inputs.push({
        components: {
          id: i,
          id_input: 5,
          disabled: true,
          validate: "required",
          name: "customer_code",
          placeholder: "Customer/Supplier Code",
          title: "Customer/Supplier Code",
          target: "_blank",
          type: "text-link",
          href: custRoute,
          value: customer_code,
        },
      });
      inputs.push({
        components: {
          id: i,
          id_input: 6,
          disabled: true,
          validate: "required",
          name: "supplier",
          placeholder: "Supplier",
          title: "Supplier",
          type: "text",
          value: `${customer_code} ${customer_name}`,
        },
      });
      inputs.push({
        components: {
          id: i,
          id_input: 7,
          disabled: true,
          validate: "required",
          name: "status",
          placeholder: "Status",
          title: "Status",
          target: "_blank",
          type: status_config == "Ready" ? "text" : "text-link",
          href: custRoute,
          value: status_config,
        },
      });
      inputs.push({
        components: {
          id: i,
          id_input: 8,
          disabled: true,
          validate: "required",
          name: "dn_value",
          placeholder: "DPP",
          title: "DPP",
          type: "text",
          value: this.formatNumber(dn_value),
        },
      });
      inputs.push({
        components: {
          id: i,
          id_input: 9,
          disabled: true,
          validate: "required",
          name: "ppn",
          placeholder: "PPN",
          title: "PPN",
          type: "text",
          value: this.formatNumber(ppn),
        },
      });
      inputs.push({
        components: {
          id: i,
          id_input: 10,
          disabled: false,
          validate: "required",
          name: "description",
          placeholder: "Description",
          title: "Description",
          type: "text",
          value: description,
          editable: false,
          editCallback: (ref) => {
            const newNote = ref.inputs[12].components.temp_value
            const type = "note"
            this.updateDesc({ invoice_id: ref.id_dn, invoice_type: 1, note: newNote, type })
          }
        },
      });

      return inputs;
    },
    getInputsDataGenHistory(data = null, i = 0, no = 0) {
      var inputs = [];
      let createdAt = ""
      let createdBy = ""
      let zDoc = 0
      let zSuccess = 0
      let zFailed = 0
      let status = ""
      let description = ""

      if (data) {
        createdAt = moment(data.CreatedAt).utc().format('YYYY-MM-DD HH:mm:ss')
        createdBy = ""
        zDoc = data.Data.debit_notes.length
        zSuccess = data.Data.debit_notes.filter(invoice => invoice.status.toLowerCase() == "success").length
        zFailed = data.Data.debit_notes.filter(invoice => invoice.status.toLowerCase() == "failed").length
        status = data.Status
        description = data.Message
      }
      inputs.push({
        components: {
          id: i,
          id_input: 0,
          disabled: true,
          validate: "required",
          name: "no",
          width: 40,
          text_align: "text-center",
          placeholder: "0",
          title: "No",
          type: "text",
          value: "" + no,
        },
      });
      inputs.push({
        components: {
          id: 0,
          id_input: 0,
          disabled: false,
          validate: "",
          name: "action",
          placeholder: "Action",
          title: "Action",
          type: "button",
          icon: "icon-eye",
          color: "warning",
          class: "p-3 pr-2",
          value: "",
          data: data,
        },
      });
      inputs.push({
        components: {
          id: i,
          id_input: 2,
          disabled: true,
          validate: "required",
          name: "created_at",
          placeholder: "Created At",
          title: "Created At",
          type: "text",
          value: createdAt,
        },
      });
      inputs.push({
        components: {
          id: i,
          id_input: 3,
          disabled: true,
          validate: "required",
          name: "created_by",
          placeholder: "Created By",
          title: "Created By",
          type: "text",
          value: createdBy,
        },
      });
      inputs.push({
        components: {
          id: i,
          id_input: 4,
          disabled: false,
          validate: "required",
          name: "z_document",
          placeholder: "Document",
          title: "Document",
          type: "text",
          value: zDoc,
        },
      });
      inputs.push({
        components: {
          id: i,
          id_input: 5,
          disabled: true,
          validate: "required",
          name: "z_success",
          placeholder: "Success",
          title: "Success",
          type: "text",
          value: zSuccess,
        },
      });
      inputs.push({
        components: {
          id: i,
          id_input: 6,
          disabled: true,
          validate: "required",
          name: "z_failed",
          placeholder: "Failed",
          title: "Failed",
          type: "text",
          value: zFailed,
        },
      });
      inputs.push({
        components: {
          id: i,
          id_input: 7,
          disabled: true,
          validate: "required",
          name: "status",
          placeholder: "Status",
          title: "Status",
          type: "text",
          value: status,
        },
      });

      return inputs;
    },
    getInputsDataGen(data = null, i = 0, no = 0) {
      var inputs = [];
      var periode = "";
      var invoice_no = "";
      var customer_code = "";
      var customer_name = "";
      var tax_invoice_type = "";
      var tax_number = "";
      var type_inv = "";
      var status_config = "";
      var custRoute = ""
      var ppn = 0
      var dn_value = 0
      var description = ""
      var status_dn = ""
      let taxNumberGenerateDate = ""
      if (data) {
        periode = data.PostingDate;
        if (periode) {
          periode = moment(periode).format("YYYY-MM-DD");
        }
        invoice_no = data.Code;
        customer_code = data.SupplierCode;
        customer_name = data.SupplierName;
        tax_number = data.TaxNumber
        tax_invoice_type = `${data.TaxType}`;
        status_config = data.StatusConfig;
        custRoute = data.Url
        dn_value = data.DnValue
        ppn = data.TaxValue
        description = data.Description != "" ? data.Description : data.DebitNoteDescription
        type_inv = this.formInvoiceType.inputs.value.text//debit note
        status_dn = data.StatusDN
        taxNumberGenerateDate = data.TaxNumberGenerateDate
      }

      inputs.push({
        components: {
          id: i,
          id_input: 0,
          disabled: true,
          validate: "",
          name: "no",
          width: 40,
          text_align: "text-center",
          placeholder: "0",
          title: "No",
          type: "text",
          value: "" + no,
        },
      });

      inputs.push({
        components: {
          id: 0,
          id_input: 0,
          disabled: false,
          validate: "",
          name: "action",
          placeholder: "Action",
          title: "Action",
          type: "button",
          icon: "icon-eye",
          color: "warning",
          class: "p-3 pr-2",
          value: "",
          data: data,
        },
      });

      inputs.push({
        components: {
          id: i,
          id_input: 1,
          disabled: true,
          validate: "required",
          name: "tax_number",
          placeholder: "Tax umber",
          title: "Tax Number",
          type: "text",
          value: tax_number,
        },
      });
      inputs.push({
        components: {
          id: i,
          id_input: 2,
          disabled: true,
          validate: "required",
          name: "debit_note_date",
          placeholder: "Debit Note Date",
          title: "Debit Note Date",
          type: "text",
          value: periode,
        },
      });
      inputs.push({
        components: {
          id: i,
          id_input: 2,
          disabled: false,
          validate: "required",
          name: "code",
          placeholder: "Code",
          title: "DN Code",
          type: "text",
          value: invoice_no,
        },
      });
      inputs.push({
        components: {
          id: i,
          id_input: 4,
          disabled: true,
          validate: "required",
          name: "tax_invoice_type",
          placeholder: "Tax Invoice Type",
          title: "Tax Invoice Type",
          type: "text",
          value: tax_invoice_type,
        },
      });
      inputs.push({
        components: {
          id: i,
          id_input: 4,
          disabled: true,
          validate: "required",
          name: "invoice_type",
          placeholder: "Invoice Type",
          title: "Invoice Type",
          type: "text",
          value: type_inv,
        },
      });
      inputs.push({
        components: {
          id: i,
          id_input: 5,
          disabled: true,
          validate: "required",
          name: "supplier_code",
          placeholder: "Supplier Code",
          title: "Supplier Code",
          type: "text",
          value: customer_code,
        },
      });
      inputs.push({
        components: {
          id: i,
          id_input: 6,
          disabled: true,
          validate: "required",
          name: "supplier_name",
          placeholder: "Supplier Name",
          title: "Supplier Name",
          type: "text",
          value: customer_name,
        },
      });
      inputs.push({
        components: {
          id: i,
          id_input: 7,
          disabled: true,
          validate: "required",
          name: "status",
          placeholder: "Status",
          title: "Status",
          target: "_blank",
          type: status_config == "Ready" ? "text" : "text-link",
          href: custRoute,
          value: status_config,
        },
      });
      inputs.push({
        components: {
          id: i,
          id_input: 8,
          disabled: true,
          validate: "required",
          name: "dn_value",
          placeholder: "DPP",
          title: "DPP",
          type: "text",
          value: this.formatNumber(dn_value),
        },
      });
      inputs.push({
        components: {
          id: i,
          id_input: 9,
          disabled: true,
          validate: "required",
          name: "ppn",
          placeholder: "PPN",
          title: "PPN",
          type: "text",
          value: this.formatNumber(ppn),
        },
      });
      inputs.push({
        components: {
          id: i,
          id_input: 10,
          disabled: false,
          validate: "required",
          name: "description",
          placeholder: "Description",
          title: "Description",
          type: "text",
          value: description,
          editCallback: (ref) => {
            const newDescription = ref.inputs[11].components.temp_value
            const type = "desc"
            this.updateDesc({ invoice_id: ref.id_dn, invoice_type: 1, description: newDescription, type })
          }
        },
      });

      inputs.push({
        components: {
          id: i,
          id_input: 11,
          disabled: true,
          validate: "required",
          name: "tax_number_generate_date",
          placeholder: "Generate Date",
          title: "Generate Date",
          type: "text",
          value: taxNumberGenerateDate,
        },
      })

      inputs.push({
        components: {
          id: i,
          id_input: 10,
          disabled: false,
          validate: "required",
          name: "status_dn",
          placeholder: "Status DN",
          title: "Status DN",
          type: "text",
          value: status_dn,
        },
      });

      return inputs;
    },
    updateDesc(data) {
      this.$vs.loading
      this.$http
        .put(`api/v1/master/tax-invoice/generate`, data)
        .then((resp) => {
          if (resp.code == 500) {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          } else if (resp.code == 200) {
            this.$vs.notify({
              color: "success",
              title: "Success",
              text: "Update description success",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
            this.$refs.outside.click()
            this.handleView()
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
          this.$vs.loading.close();
        })
        .catch((err) => {
          this.$vs.loading.close();
          this.$vs.notify({
            color: "danger",
            title: "Error",
            text: err,
            position: "top-right",
            iconPack: "feather",
            icon: "icon-x-circle",
          });
        });
    },
    getTaxType() {
      this.$http
        .get(`api/v1/master/tax-type`)
        .then((resp) => {
          if (resp.code == 500) {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          } else if (resp.code == 200) {
            resp.data.records.sort((a, b) => a.code - b.code)
            this.formTaxInvoiceType.inputs.option = [{ ID: 0, text: "ALL" }, ...resp.data.records.map((t) => ({ ID: t.ID, text: `${t.code} ${t.name}` }))];
            this.formTaxInvoiceType.inputs.value = { ID: 0, text: "ALL" }
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
          this.$vs.loading.close();
        })
        .catch((err) => {
          this.$vs.loading.close();
          this.$vs.notify({
            color: "danger",
            title: "Error",
            text: err,
            position: "top-right",
            iconPack: "feather",
            icon: "icon-x-circle",
          });
        });
    },
    getInputsHead() {
      var inputs = [];

      inputs.push({
        components: {
          id: 0,
          id_input: 0,
          disabled: true,
          validate: "required",
          name: "",
          width: 40,
          text_align: "text-center",
          placeholder: "0",
          title: "No",
          type: "input",
          value: "1",
        },
      });
      inputs.push({
        components: {
          id: 0,
          id_input: 2,
          disabled: true,
          validate: "required",
          name: "checkbox",
          placeholder: "Checkbox",
          title: "checkbox",
          head: "checkbox", //checkbox/title
          head_val: false, //for head=checkbox
          type: "checkbox",
          value: false,
        },
      });
      inputs.push({
        components: {
          id: 0,
          id_input: 0,
          disabled: false,
          validate: "",
          name: "action",
          placeholder: "Action",
          title: "Action",
          type: "button",
          icon: "icon-trash",
          color: "danger",
          // text: "Delete",
          value: "",
        },
      });
      inputs.push({
        components: {
          id: 0,
          id_input: 1,
          disabled: true,
          validate: "required",
          name: "posting_date",
          placeholder: "Debit Note Date",
          title: "Debit Note Date",
          type: "input",
          value: "",
        },
      });
      inputs.push({
        components: {
          id: 0,
          id_input: 2,
          disabled: false,
          validate: "required",
          name: "code",
          placeholder: "Code",
          title: "DN Code",
          type: "input",
          value: "",
        },
      });
      inputs.push({
        components: {
          id: 0,
          id_input: 4,
          disabled: true,
          validate: "required",
          name: "",
          placeholder: "Tax Invoice Type",
          title: "Tax Type",
          type: "input",
          value: "",
        },
      });
      inputs.push({
        components: {
          id: 0,
          id_input: 4,
          disabled: true,
          validate: "required",
          name: "invoice_type",
          placeholder: "Invoice Type",
          title: "Invoice Type",
          type: "input",
          value: "",
        },
      });
      inputs.push({
        components: {
          id: 0,
          id_input: 5,
          disabled: true,
          validate: "required",
          name: "supplier_code",
          placeholder: "Supplier Code",
          title: "Supplier Code",
          type: "input",
          value: "",
        },
      });
      inputs.push({
        components: {
          id: 0,
          id_input: 6,
          disabled: true,
          validate: "required",
          name: "supplier_code",
          placeholder: "Supplier",
          title: "Supplier",
          type: "input",
          value: "",
        },
      });
      inputs.push({
        components: {
          id: 0,
          id_input: 7,
          disabled: true,
          validate: "required",
          name: "",
          placeholder: "Status",
          title: "Status",
          type: "input",
          value: "",
        },
      });
      inputs.push({
        components: {
          id: 0,
          id_input: 8,
          disabled: true,
          validate: "required",
          name: "dn_value",
          placeholder: "DPP",
          title: "DPP",
          type: "input",
          value: "",
        },
      });
      inputs.push({
        components: {
          id: 0,
          id_input: 9,
          disabled: true,
          validate: "required",
          name: "tax_value",
          placeholder: "PPN",
          title: "PPN",
          type: "input",
          value: "",
        },
      });
      inputs.push({
        components: {
          id: 0,
          id_input: 10,
          disabled: true,
          validate: "required",
          name: "description",
          placeholder: "Description",
          title: "Description",
          type: "input",
          value: "",
        },
      });

      return inputs;
    },
    getInputsHeadDataGenHistory() {
      const inputs = []

      inputs.push({
        components: {
          id: 0,
          id_input: 0,
          disabled: true,
          validate: "required",
          name: "no",
          width: 40,
          text_align: "text-center",
          placeholder: "0",
          title: "No",
          type: "text",
          value: ""
        },
      });

      inputs.push({
        components: {
          id: 0,
          id_input: 0,
          disabled: true,
          validate: "required",
          name: "action",
          width: 40,
          text_align: "text-center",
          placeholder: "0",
          title: "Action",
          type: "text",
          value: ""
        },
      });

      inputs.push({
        components: {
          id: 0,
          id_input: 2,
          disabled: true,
          validate: "required",
          name: "created_at",
          placeholder: "Created At",
          title: "Created At",
          type: "input",
          value: "",
        },
      });

      inputs.push({
        components: {
          id: 0,
          id_input: 2,
          disabled: true,
          validate: "required",
          name: "created_by",
          placeholder: "Created By",
          title: "Created By",
          type: "input",
          value: "",
        },
      });

      inputs.push({
        components: {
          id: 0,
          id_input: 2,
          disabled: true,
          validate: "required",
          name: "z_document",
          placeholder: "Document",
          title: "Document",
          type: "input",
          value: "",
        },
      });

      inputs.push({
        components: {
          id: 0,
          id_input: 2,
          disabled: true,
          validate: "required",
          name: "z_success",
          placeholder: "Success",
          title: "Success",
          type: "input",
          value: "",
        },
      });

      inputs.push({
        components: {
          id: 0,
          id_input: 2,
          disabled: true,
          validate: "required",
          name: "z_failed",
          placeholder: "Failed",
          title: "Failed",
          type: "input",
          value: "",
        },
      });

      inputs.push({
        components: {
          id: 0,
          id_input: 2,
          disabled: true,
          validate: "required",
          name: "status",
          placeholder: "Status",
          title: "Status",
          type: "input",
          value: "",
        },
      });

      return inputs
    },
    getInputsHeadDataGen() {
      var inputs = [];

      inputs.push({
        components: {
          id: 0,
          id_input: 0,
          disabled: true,
          validate: "required",
          name: "",
          width: 40,
          text_align: "text-center",
          placeholder: "0",
          title: "No",
          type: "input",
          value: "",
        },
      });
      inputs.push({
        components: {
          id: 0,
          id_input: 0,
          disabled: false,
          validate: "",
          name: "action",
          placeholder: "Action",
          title: "Action",
          type: "button",
          icon: "icon-trash",
          color: "danger",
          value: "",
        },
      });

      inputs.push({
        components: {
          id: 0,
          id_input: 2,
          disabled: true,
          validate: "required",
          name: "tax_number",
          placeholder: "Tax umber",
          title: "Tax Number",
          type: "input",
          value: "",
        },
      });
      inputs.push({
        components: {
          id: 0,
          id_input: 3,
          disabled: true,
          validate: "required",
          name: "posting_date",
          placeholder: "Debit Note Date",
          title: "Debit Note Date",
          type: "input",
          value: "",
        },
      });
      inputs.push({
        components: {
          id: 0,
          id_input: 4,
          disabled: false,
          validate: "required",
          name: "code",
          placeholder: "Code",
          title: "DN Code",
          type: "input",
          value: "",
        },
      });
      inputs.push({
        components: {
          id: 0,
          id_input: 5,
          disabled: true,
          validate: "required",
          name: "",
          placeholder: "Tax Invoice Type",
          title: "Tax Invoice Type",
          type: "input",
          value: "",
        },
      });
      inputs.push({
        components: {
          id: 0,
          id_input: 5,
          disabled: true,
          validate: "required",
          name: "",
          placeholder: "Invoice Type",
          title: "Invoice Type",
          type: "input",
          value: "",
        },
      });
      inputs.push({
        components: {
          id: 0,
          id_input: 6,
          disabled: true,
          validate: "required",
          name: "supplier_code",
          placeholder: "Supplier Code",
          title: "Supplier Code",
          type: "input",
          value: "",
        },
      });
      inputs.push({
        components: {
          id: 0,
          id_input: 7,
          disabled: true,
          validate: "required",
          name: "supplier_name",
          placeholder: "Supplier Name",
          title: "Supplier Name",
          type: "input",
          value: "",
        },
      });
      inputs.push({
        components: {
          id: 0,
          id_input: 7,
          disabled: true,
          validate: "required",
          name: "",
          placeholder: "Status",
          title: "Status",
          type: "input",
          value: "",
        },
      });
      inputs.push({
        components: {
          id: 0,
          id_input: 8,
          disabled: true,
          validate: "required",
          name: "dn_value",
          placeholder: "DPP",
          title: "DPP",
          type: "input",
          value: "",
        },
      });
      inputs.push({
        components: {
          id: 0,
          id_input: 9,
          disabled: true,
          validate: "required",
          name: "tax_value",
          placeholder: "PPN",
          title: "PPN",
          type: "input",
          value: "",
        },
      });
      inputs.push({
        components: {
          id: 0,
          id_input: 10,
          disabled: false,
          validate: "required",
          name: "description",
          placeholder: "Description",
          title: "Description",
          type: "text",
          value: "",
        },
      });
      inputs.push({
        components: {
          id: 0,
          id_input: 11,
          disabled: false,
          validate: "required",
          name: "tax_number_generate_date",
          placeholder: "Generate Date",
          title: "Generate Date",
          type: "input",
          value: "",
        }
      })
      inputs.push({
        components: {
          id: 0,
          id_input: 12,
          disabled: false,
          validate: "required",
          name: "",
          placeholder: "Status DN",
          title: "Status DN",
          type: "input",
          value: "status_dn",
        },
      });

      return inputs;
    },
    getIDItemRef() {
      return 0;
    },
    getDataTaxAllocation() {
      const params = {
        tax_year: this.formYear.inputs.value.getFullYear(),
      };
      this.$http
        .get(`api/v1/master/data-reference`, { params })
        .then((resp) => {
          console.log(resp);
          console.log('data-tax')
          if (resp.code == 500) {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          } else if (resp.code == 200) {
            const snAvailable = resp.data.records.reduce((acc, curr) => acc + curr.Remaining, 0)
            this.total_sn_available = snAvailable;
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
          this.$vs.loading.close();
        })
        .catch((err) => {
          this.$vs.loading.close();
          this.$vs.notify({
            color: "danger",
            title: "Error",
            text: err,
            position: "top-right",
            iconPack: "feather",
            icon: "icon-x-circle",
          });
          console.log("error boss:", err);
        });
    },
    handleSort(order) {
      if (this.table.order == order) {
        if (this.table.sort == "desc") this.table.sort = "asc"
        else this.table.sort = "desc"
      }
      this.table.order = order;
      this.handleView()
    },
    handleChangePage(page) {
      this.table.page = page;
      this.handleView()
    },
    setStartEnd() {
      let valStart =
        this.table.length * this.table.page - this.table.length + 1;

      if (valStart > this.table.total) {
        valStart = 1;
      }
      if (this.table.total == 0) {
        valStart = 0;
      }
      let valEnd = this.table.length * this.table.page;

      if (valEnd > this.table.total) {
        valEnd = this.table.total;
      }

      if (
        this.table.totalSearch < this.table.total &&
        this.table.search != ""
      ) {
        valEnd = this.table.totalSearch;
      }

      this.table.start = valStart;
      this.table.end = valEnd;
    },
    getDataSN() {
      if (this.formMonth.inputs.value && this.formYear.inputs.value) {
        this.$vs.loading();
        var month = "00"
        var year = "2022"
        if (this.formMonth.inputs.value) {
          month = this.formMonth.inputs.value.getMonth()
        }
        if (this.formYear.inputs.value) {
          year = this.formYear.inputs.value.getFullYear()
        }

        const params = {
          length: this.table.length,
          page: this.table.page,
          search: this.table.search,
          order: this.table.order,
          sort: this.table.sort,
          invoice_type: this.formInvoiceType.inputs.value.id,//debit note=>1
          supplier_ids: this.formSupplier.inputs.value.map(s => s.ID),
          tax_periode: year + "-" + ((month + 1) < 10 ? "0" + (month + 1) : "" + (month + 1)),
        };
        this.$http
          .get(`api/v1/master/tax-invoice/view-invoice`, { params })
          .then((resp) => {

            if (resp.code == 500) {
              this.$vs.notify({
                color: "danger",
                title: "Error",
                text: resp.message,
                position: "top-right",
                iconPack: "feather",
                icon: "icon-x-circle",
              });
            } else if (resp.code == 200) {
              this.id_dn = []
              var dataInvoice = [];
              this.table.total = resp.data.total_record
              this.table.totalPage = resp.data.total_page;
              this.table.totalSearch = resp.data.total_record_search;
              this.table.length = resp.data.total_record_per_page;
              this.setStartEnd()
              var no = this.table.start
              resp.data.records.forEach((dt, i) => {
                // if (i < 1000) {
                let id = 0
                dataInvoice.push({
                  id: i,
                  id_dn: dt.ID,
                  action: false,
                  inputs: this.getInputs(dt, i, no),
                });
                no++
                // }
              });


              this.itemRefs = dataInvoice;
              if (this.data_head) {
                this.data_head.head_val = false;
              }
              this.getDataTaxAllocation();
            }
            this.$vs.loading.close();
          })
          .catch((err) => {
            this.$vs.loading.close();
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: err,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
            console.log("error boss:", err);
          });
      }
    },
    handleView() {
      if (this.tabs == 0) {
        this.getDataSN()
      } else if (this.tabs == 2) {
        this.getDataGenHistories()
      } else {
        this.table.stripe = false
        this.getDatGen()
      }
    },
    getDatGen() {
      this.$vs.loading();
      var month = "00"
      var year = "2022"
      if (this.formMonth.inputs.value) {
        month = this.formMonth.inputs.value.getMonth()
      }
      if (this.formYear.inputs.value) {
        year = this.formYear.inputs.value.getFullYear()
      }

      const params = {
        length: this.table.length,
        page: this.table.page,
        search: this.table.search,
        order: this.table.order,
        sort: this.table.sort,
        supplier_ids: this.formSupplier.inputs.value.map(s => s.ID),
        tax_periode: year + "-" + ((month + 1) < 10 ? "0" + (month + 1) : "" + (month + 1)),
        invoice_type: this.formInvoiceType.inputs.value.text,//debit note
      };
      this.$http
        .get(`api/v1/master/tax-invoice/data-generate`, { params })
        .then((resp) => {
          if (resp.code == 500) {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          } else if (resp.code == 200) {
            var dataInvoice = [];
            this.table.total = resp.data.total_record
            this.table.totalPage = resp.data.total_page;
            this.table.totalSearch = resp.data.total_record_search;
            this.table.length = resp.data.total_record_per_page;
            this.setStartEnd()
            var no = this.table.start
            resp.data.dataInvoice.forEach((dt, i) => {
              if (i < 1000) {
                dataInvoice.push({
                  id: i,
                  id_dn: dt.ID,
                  action: false,
                  inputs: this.getInputsDataGen(dt, i, no),
                });
              }
              no++
            });
            this.dtGen = dataInvoice;
            if (this.data_head) {
              this.data_head.head_val = false;
            }
            this.getDataTaxAllocation();
          }
          this.$vs.loading.close();
        })
        .catch((err) => {
          this.$vs.loading.close();
          this.$vs.notify({
            color: "danger",
            title: "Error",
            text: err,
            position: "top-right",
            iconPack: "feather",
            icon: "icon-x-circle",
          });
          console.log("error boss:", err);
        });
    },
    handleSubmit() {
      var month = "00"
      var year = "2022"
      if (this.formMonth.inputs.value) {
        month = this.formMonth.inputs.value.getMonth()
      }
      if (this.formYear.inputs.value) {
        year = this.formYear.inputs.value.getFullYear()
      }

      var data = this.itemRefs.filter((dt) => {
        return dt.inputs[1].components.value;
      });
      console.log(data);
      var tax_invoice_type = "";
      if (this.formTaxInvoiceType) {
        tax_invoice_type = this.formTaxInvoiceType.inputs.value.ID;
      }
      const params = {
        supplier_id: this.formSupplier.inputs.value.ID,
        tax_period: year + "-" + ((month + 1) < 10 ? "0" + (month + 1) : "" + (month + 1)),
        data_id: this.id_dn,
        tax_invoice_type: tax_invoice_type,
        type_exp: "xml",
        invoice_type: this.formInvoiceType.inputs.value.id,//debit note
        tax_type: this.formTaxInvoiceType.inputs.value.ID,
        description: this.descs,
        is_all: false,
      };
      console.log(params)
      this.$vs.loading();
      this.$http
        .post(`api/v1/master/tax-invoice/export-xml`, params)
        .then((resp) => {
          this.$vs.loading.close();
          if (resp.code == 500) {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
            if (resp.data) {
              this.id_dn = []
              resp.data.forEach((v) => {
                this.id_dn.push(v)
              })
              this.handleView()
            }

          } else if (resp.code == 200) {
            this.$vs.notify({
              color: "success",
              title: "Success",
              text: "Export process in background, please check in history tab",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
            this.handleView();
            this.getDataTaxAllocation();
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
        })
        .catch((err) => {
          this.$vs.loading.close();
          this.$vs.notify({
            color: "danger",
            title: "Error",
            text: err,
            position: "top-right",
            iconPack: "feather",
            icon: "icon-x-circle",
          });
          console.log("error boss:", err);
        });
    },
    handleEdit() { },
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    async getDataGenHistories() {
      try {
        const params = {
          territory_id: 0,
          operating_unit_id: 0,
        };

        const response = await this.$http
          .get(`api/v1/master/tax-invoice/get-history-tax-generate`, { params })

        if (response.code == 500) {
          throw new Error(response.message)
        }

        const data = response.data.records

        this.table.total = response.data.total_record
        this.table.totalPage = response.data.total_page
        this.table.length = response.data.total_record_per_page

        this.setStartEnd()

        this.tmpdtGenHistory.inputs = this.getInputsHeadDataGenHistory()


        let no = this.table.start
        const dataInvoice = data.map((item, index) => (
          {
            id: index,
            id_invoice: item.ID,
            action: false,
            inputs: this.getInputsDataGenHistory(item, index, no++)
          }
        ))

        this.dtGenHistory = [...dataInvoice]

      } catch (error) {
        console.log(error)
      }

    },
    getData() {
      this.$vs.loading();
      const params = {
        type: 1,
      }
      this.$http
        .get(`api/v1/master/tax-invoice`, { params })
        .then((resp) => {
          this.$vs.loading.close();
          if (resp.code == 500) {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          } else if (resp.code == 200) {
            this.formInput.inputs.value = resp.data.company;
            this.formInput.inputs.disabled = true;
            console.log(this.formInput);
            var dataSelect = resp.data.supplier.map(s => ({ ID: s.ID, text: `${s.Code} - ${s.Name}` }));
            this.tempSupplier = [{ ID: 0, text: "All" }, ...dataSelect]
            this.formSupplier.inputs.option = [{ ID: 0, text: "All" }, ...dataSelect]
            this.formSupplier.inputs.value = [{ ID: 0, text: "All" }];
          }
        })
        .catch((err) => {
          this.$vs.loading.close();
          this.$vs.notify({
            color: "danger",
            title: "Error",
            text: err,
            position: "top-right",
            iconPack: "feather",
            icon: "icon-x-circle",
          });
          console.log("error boss:", err);
        });
    },
    updateforminput(selected, component) {
      if (component.name == "supplier") {
        this.getDataTerritory(selected);
      } else if (component.name == "allocation_type") {
        this.automateInputTaxNumber(selected);
      }
    },
  },
};
</script>

<style scoped>
.core {
  -webkit-transition: all 1s ease-out;
  -moz-transition: all 1s ease-out;
  -o-transition: all 1s ease-out;
  transition: all 1s ease-out;
}

.vs-con-table .vs-con-tbody .vs-table--tbody-table {
  font-size: 12px;
}

.vertical-divider {
  border-left: 1px solid #7367f0;
  min-height: 100px;
  padding: 5px;
}
</style>
