<template>
  <vx-card class="pt-5" :title="card.title+(card.subTitle?' | ' : '')+card.subTitle">
    <vs-tabs>
      <vs-tab @click="card.subTitle='AR Invoice'" label="AR Invoice">
        <div class="con-tab-ejemplo">
          <export_ar_invoice />
        </div>
      </vs-tab>
      <vs-tab @click="card.subTitle='Debit Note'" label="Debit Note">
        <div class="con-tab-ejemplo">
          <export_dn_xml />
        </div>
      </vs-tab>
    </vs-tabs>
  </vx-card>
</template>

<script>
import export_dn_xml from './export-dn-xml.vue'
import export_ar_invoice from './export-ar-invoice-xml.vue'

export default {
  components: {
    export_dn_xml,
    export_ar_invoice,
  },
  data(){
    return {
      card: {
        title: "Export",
        subTitle: "AR Invoice"
      }
    }
  }
}
</script>
