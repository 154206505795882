<template>
  <div class="vx-row" style="margin-top: 10px">
    <div class="vx-col md:w-1/1 w-full">
      <div class="vx-row mb-6 mx-4">
        <div class="vx-col sm:w-1/5 w-full">
          <span>Company</span>
        </div>
        <div class="vx-col sm:w-2/5 w-full">
          <formInputs :components="this.formCompany.inputs" />
        </div>
      </div>
      <div class="vx-row mb-6 mx-4">
        <div class="vx-col sm:w-1/5 w-full">
          <span>Supplier</span>
        </div>
        <div class="vx-col sm:w-2/5 w-full">
          <formInputs :components="this.formSupplier.inputs" />
        </div>
      </div>
      <div class="vx-row mb-6 mx-4">
        <div class="vx-col sm:w-1/5 w-full">
          <span>Tax Invoice Period</span>
        </div>
        <div class="vx-col sm:w-1/5 w-full">
          <formInputs :components="this.formMonth.inputs" />
        </div>
        <div class="vx-col sm:w-1/5 w-full">
          <formInputs :components="this.formYear.inputs" />
        </div>
      </div>
      <div class="vx-row mb-6 mx-4">
        <div class="vx-col sm:w-1/5 w-full">
          <span>Search</span>
        </div>
        <div class="vx-col sm:w-2/5 w-full">
          <vs-input class="w-full" />
        </div>
      </div>
      <div class="vx-row mb-6 mx-4">
        <div class="vx-col sm:w-4/5 w-full text-center">
          <vs-button class="mr-4" color="primary" icon-pack="feather">View</vs-button>
          <vs-button class="mr-4" color="danger" icon-pack="feather" type="border" @click="$refs.file.click()">Import
          </vs-button>
          <input type="file" ref="file" @change="handleImport" style="display: none;"/>
        </div>
      </div>
      <vs-row class="vx-row mb-6 mx-4">

      </vs-row>
      <vs-divider class="mb-2"/>
      <vs-tabs>
        <vs-tab label="History Import"></vs-tab>
      </vs-tabs>
    </div>
  </div>
</template>
<script>
import formInputs from '../form_inputs.vue'

export default {
  components: {
    formInputs,
  },
  data() {
    return {
      formCompany: {
        id: 1,
        inputs: {
          disabled: true,
          type: "input",
          value: ""
        }
      },
      formSupplier: {
        id: 2,
        inputs: {
          disabled: false,
          placeholder: "Type to search",
          type: "multiselect",
          value: [{ ID: 0, text: "All" }],
          option: [],
          multiple: true,
          allowEmpty: false,
          internal_search: false,
          clear_on_select: false,
          track_by: "ID",
        }
      },
      formMonth: {
        id: 3,
        inputs: this.formInputMonth(),
      },
      formYear: {
        id: 4,
        inputs: this.formInputYear(),
      }
    }
  },
  methods: {
    formInputMonth(){
      return {
        disabled: false,
        type: "date",
        min_view: "month",
        format: "MM",
        placeholder: "Select Month",
        value: new Date(),
        multiple: false,
        allowEmpty: false,
      }
    },
    formInputYear(){
      return {
        disabled: false,
        type: "date",
        min_view: "year",
        format: "yyyy",
        placeholder: "Select Year",
        value: new Date(),
        multiple: false,
        allowEmpty: false,
      }
    },
    async getData(){
      try {
        this.$vs.loading()
        const params = {
          type: 1,
        }

        const response = await this.$http
          .get(`api/v1/master/tax-invoice`, { params })

        if (response.status !== 500) {
          throw new Error(response.message)
        }

        this.formCompany.inputs.value = response.data.company

      } catch (error) {
        this.$vs.notify({
          color: "danger",
          title: "Error",
          text: error,
          position: "top-right",
          iconPack: "feather",
          icon: "icon-x-circle",
        });
      } finally {
        this.$vs.loading.close();
      }
    },
    async handleImport(file) {
      try {
        console.log(file);
        this.$vs.loading();
        const formData = new FormData()
        formData.append("file", file.target.files[0])
        const response = await this.$http.post(`api/v1/master/tax-invoice/import-xml`, formData)

        if (response.code !== 200) {
          throw new Error(response.message);
        }

        this.$vs.dialog({
          type: "alert",
          color: "success",
          title: `Success`,
          text: response.message,
          acceptText: "Confirm",
        });
      } catch (error) {
        this.$vs.dialog({
          type: "alert",
          color: "danger",
          title: `Error`,
          text: error,
          acceptText: "Confirm",
        });
      } finally {
        this.$vs.loading.close();
      }
    }
  }
}
</script>
